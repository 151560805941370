import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export default class Select2 extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      selOption: [],
      inputValue: '',
    };

    this.styles = {
      input: (styles) => ({
        ...styles,
        height: 35,
        marginTop: 0,
        paddingTop: this.props.payments ? 10 : -9,
      }),
      placeholder: (styles) => ({ ...styles, height: 32, paddingTop: 8 }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: '#00B2EE',
        color: 'white',
      }),
      valueContainer: (styles) => ({
        ...styles,
        paddingTop: 0,
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: 'white',
        fontSize: 14,
      }),
      ...this.props.styles,
    };
    this.options = Object.keys(this.props.options).map((key) => ({
      value: key,
      label: this.props.options[key],
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.options) !== JSON.stringify(nextProps.options)
    ) {
      this.options = Object.keys(nextProps.options).map((key) => ({
        value: key,
        label: nextProps.options[key],
      }));
    }

    if (!nextProps.multi) {
      const selectionsFromProps = [];
      for (const i in nextProps.options) {
        if (nextProps.value === i) {
          selectionsFromProps.push({ value: i, label: nextProps.options[i] });
        }
      }
      if (
        JSON.stringify(this.state.selOption) !==
        JSON.stringify(selectionsFromProps)
      ) {
        this.setState({ selOption: selectionsFromProps });
      }
    } else {
      const selectionsFromProps = [];
      for (const i in nextProps.options) {
        if (nextProps.value.includes(i)) {
          selectionsFromProps.push({ value: i, label: nextProps.options[i] });
        }
      }
      if (
        JSON.stringify(this.state.selOption) !==
        JSON.stringify(selectionsFromProps)
      ) {
        this.setState({ selOption: selectionsFromProps });
      }
    }
  }
  componentDidUpdate() {
    // I make the selections into a usable format, because in some cases
    // they come as id's
    if (!this.props.multi) {
      const selectionsFromProps = [];
      for (const i in this.props.options) {
        if (this.props.value == i) {
          selectionsFromProps.push({ value: i, label: this.props.options[i] });
        }
      }
      if (
        JSON.stringify(this.state.selOption) !==
        JSON.stringify(selectionsFromProps)
      ) {
        this.setState({ selOption: selectionsFromProps });
      }
    } else {
      const selectionsFromProps = [];
      for (const i in this.props.options) {
        if (this.props.value.includes(i)) {
          selectionsFromProps.push({ value: i, label: this.props.options[i] });
        }
      }
      if (
        JSON.stringify(this.state.selOption) !==
        JSON.stringify(selectionsFromProps)
      ) {
        this.setState({ selOption: selectionsFromProps });
      }
    }
  }

  componentDidMount() {
    // I make the selections into a usable format, because in some cases
    // they come as id's
    if (!this.props.multi) {
      const selectionsFromProps = [];
      for (const i in this.props.options) {
        if (this.props.value == i) {
          selectionsFromProps.push({ value: i, label: this.props.options[i] });
        }
      }
      if (
        JSON.stringify(this.state.selOption) !==
        JSON.stringify(selectionsFromProps)
      ) {
        this.setState({ selOption: selectionsFromProps });
      }
    } else {
      const selectionsFromProps = [];
      for (const i in this.props.options) {
        if (this.props.value.includes(i)) {
          selectionsFromProps.push({ value: i, label: this.props.options[i] });
        }
      }
      if (
        JSON.stringify(this.state.selOption) !==
        JSON.stringify(selectionsFromProps)
      ) {
        this.setState({ selOption: selectionsFromProps });
      }
    }
  }

  onChange(key = [], action) {
    return this.props.multi
      ? action.action === 'remove-value'
        ? this.props.onChange(
            key
              .filter((v) => v.value !== action.removedValue.value)
              .map((v) => v.value),
            action
          )
        : this.props.onChange(
            key === null ? [] : key.map((v) => v.value),
            action,
            this.state.inputValue
          )
      : this.props.onChange(key.value, action);
  }

  search() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.props.onSearch(this.state.inputValue);
    }, 400);
  }

  handleInputChange = (inputValue) => {
    this.setState(
      {
        inputValue,
      },
      () => this.search()
    );
  };

  render() {
    return (
      <Select
        isDisabled={this.props.disabled}
        className={this.props.className || ''}
        //the below bypasses the unique key problems
        onInputChange={this.handleInputChange}
        closeMenuOnSelect={true}
        inputValue={this.state.inputValue}
        getOptionValue={() => Math.random()}
        options={(() => {
          const ret = this.options.filter((o) => {
            return !this.state.selOption
              .map((k) => k.value.toString())
              .includes(o.value.toString());
          });
          if (this.props.allowCreate && this.state.inputValue) {
            if (
              !ret.filter(
                (o) =>
                  o.value.toLowerCase() == this.state.inputValue.toLowerCase()
              )[0]
            ) {
              ret.unshift({
                value: 'new',
                label: `Create tag "${this.state.inputValue}"`,
              });
            }
          }
          return ret;
        })()}
        components={this.props.components}
        name={this.props.name}
        onChange={this.onChange}
        value={this.state.selOption}
        placeholder={this.props.placeholder}
        styles={this.styles}
        isMulti={this.props.multi}
      />
    );
  }
}

Select2.propTypes = {
  allowCreate: PropTypes.bool,
  placeholder: PropTypes.any,
  components: PropTypes.object,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  multi: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  payments: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  styles: PropTypes.object,
};

Select2.defaultProps = {
  className: '',
  components: {},
  placeholder: 'Select',
  onChange: () => {},
  onSearch: () => {},
  options: {},
  multi: false,
  value: '',
  payments: false,
  styles: {},
  allowCreate: false,
};
