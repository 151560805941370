import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import files from '@config/files';

export default class KMBDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.onDropAccepted = this.onDropAccepted.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.getFileInfoMessage = this.getFileInfoMessage.bind(this);
    this.dropzoneProps = {};

    this.state = {
      files: [],
      disabled: props.max ? props.value.length >= props.max : false,
    };

    if (this.props.styleType === 'simple') {
      this.promptMessage = [<span key="decorative" className="icon-upload" />];
      this.dropzoneProps.style = {
        width: '100%',
        height: 'auto',
        borderWidth: 1,
        borderColor: '#1DA4CF',
        borderStyle: 'dashed',
        borderRadius: 4,
        backgroundImage: 'none',
        padding: '25px 15px',
        backgroundColor: '#F5F9FD',
      };
      this.dropzoneProps.activeStyle = {
        borderStyle: 'dashed',
        borderColor: '#1DA4CF',
      };
      this.dropzoneProps.acceptStyle = {
        borderStyle: 'dashed',
        borderColor: '#14DA9E',
      };
      this.dropzoneProps.rejectStyle = {
        borderStyle: 'dashed',
        borderColor: '#F4741E',
      };

      this.dropzoneProps.accept = this.props.accept;
    } else {
      this.dropzoneProps = {
        style: this.props.style,
        activeStyle: this.props.activeStyle,
        acceptStyle: this.props.acceptStyle,
        rejectStyle: this.props.rejectStyle,
        accept: this.props.accept,
      };
      this.promptMessage = this.props.promptMessage;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      disabled: nextProps.max ? nextProps.value.length >= nextProps.max : false,
      files: nextProps.files || [],
    });
  }

  onDropAccepted(files) {
    this.setState(
      {
        files: this.props.multiple ? [...this.state.files, ...files] : files,
      },
      () => this.props.onDropAccepted(this.state.files)
    );
  }

  onDropRejected() {
    let acceptedTypes = '';
    const typesStringList = this.props.accept?.split(', ');
    typesStringList.forEach((type) => {
      const minifiedType = type
        .replace('image/', '')
        .replace('application/', '');
      acceptedTypes =
        acceptedTypes === ''
          ? acceptedTypes.concat('', minifiedType)
          : acceptedTypes.concat(', ', minifiedType);
    });

    this.props.onDropRejected(
      `${this.props.lang['onDropRejected']}: ${acceptedTypes}`
    );
  }

  removeFile(e, index) {
    e.stopPropagation();
    this.setState(
      {
        files: this.state.files.filter((f, i) => i !== index),
      },
      () => this.props.onFileRemove(index)
    );
  }

  getFileInfoMessage() {
    const { name } = this.props;
    const file =
      name && files.list.hasOwnProperty(name) ? files.list[name] : null;

    return (
      <div className="file-info">
        {file && (
          <p className="dimensions">
            {this.props.lang['fileInfoDimentions']} : {file.dimensions}
          </p>
        )}
        <p className="max-size">
          {this.props.lang['maxFileSize']}:{' '}
          {this.props.maxFileSize ? this.props.maxFileSize : files.maxFileSize}
          MB
        </p>
        <p className="file-name-restriction">{this.props.lang['fileName']}</p>
      </div>
    );
  }

  render() {
    const fileType =
      this.props.accept === 'image/jpeg, image/png, image/gif, image/svg+xml'
        ? 'image'
        : 'document';
    return (
      <div
        className={`kmb-dropzone ${this.props.styleType}${
          this.props.active && !this.state.disabled ? '' : ' disabled'
        }`}>
        {this.props.value.length > 0 && (
          <div className="selected-files">
            {this.props.value.map((f, index) => (
              <p key={`file-${index}`} className="file">
                {fileType === 'image' ? (
                  <span>
                    <img src={f.url} alt={f.fileName} width="50" />
                    {this.props.active && (
                      <i
                        className="icon-close"
                        aria-hidden="true"
                        onClick={() => this.props.onFileDelete(f)}
                      />
                    )}
                  </span>
                ) : (
                  <span>
                    <i className="icon-presentation" aria-hidden="true" />
                    <a
                      className="file-name"
                      href={f.url}
                      title={f.fileName}
                      target="_blank"
                      rel="noreferrer">
                      {f.fileName}
                    </a>
                    {this.props.active && (
                      <i
                        className="icon-close"
                        aria-hidden="true"
                        onClick={() => this.props.onFileDelete(f)}
                      />
                    )}
                  </span>
                )}
              </p>
            ))}
          </div>
        )}
        <Dropzone
          {...this.dropzoneProps}
          multiple={this.props.multiple}
          onDrop={this.onDrop}
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
          className="zone-area">
          <div className="vertical-center">
            <div className="text-area">
              {this.props.children}
              <div className="wrap">
                {this.promptMessage !== '' && (
                  <p className="prompt-message">{this.promptMessage}</p>
                )}
                <p className="details">
                  {this.props.lang['dropFilesHere']}{' '}
                  <span>{this.props.lang['browse']}</span>
                </p>
              </div>
              {this.getFileInfoMessage()}
              {this.state.files.length > 0 && (
                <p className="file-names">
                  {this.state.files.map((f, index) => (
                    <span key={`file-${index}`}>
                      {f.name}
                      <span
                        className="fa fa-close"
                        onClick={(e) => this.removeFile(e, index)}
                      />
                    </span>
                  ))}
                </p>
              )}
            </div>
          </div>
        </Dropzone>
        {this.state.disabled && (
          <p className="limit-reached">
            {this.props.lang['filesLimitReached']} {this.props.max}
          </p>
        )}
      </div>
    );
  }
}

KMBDropzone.propTypes = {
  style: PropTypes.object,
  activeStyle: PropTypes.object,
  promptMessage: PropTypes.any,
  maxSize: PropTypes.number,
  accept: PropTypes.string,
  maxFileSize: PropTypes.string,
  activeClassName: PropTypes.string,
  active: PropTypes.bool,
  styleType: PropTypes.string,
  acceptStyle: PropTypes.object,
  rejectStyle: PropTypes.object,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  children: PropTypes.element,
  multiple: PropTypes.bool,
  onFileRemove: PropTypes.func,
  onFileDelete: PropTypes.func,
  value: PropTypes.array,
  files: PropTypes.array,
  max: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  lang: PropTypes.object,
};

KMBDropzone.defaultProps = {
  files: [],
  lang: require('@config/languages')['en'],
  style: {
    width: '100%',
    height: 500,
    borderWidth: 1,
    borderColor: 'transparent',
    borderStyle: 'dashed',
    borderRadius: 4,
    backgroundImage: 'url( /images/cover-icon.png )',
  },
  activeStyle: {
    borderStyle: 'dashed',
    borderColor: '#1DA4CF',
    backgroundColor: '#F5F9FD',
    backgroundImage: 'url( /images/upload-icon.png )',
  },
  acceptStyle: {
    borderStyle: 'dashed',
    borderColor: '#14DA9E',
    backgroundColor: '#F5F9FD',
  },
  rejectStyle: {
    borderStyle: 'dashed',
    borderColor: '#F4741E',
    backgroundColor: '#F5F9FD',
  },
  accept: 'image/jpeg, image/png, image/gif, image/svg+xml',
  onDropRejected: () => {},
  onDropAccepted: () => {},
  onFileRemove: () => {},
  onFileDelete: () => {},
  activeClassName: '',
  active: true,
  styleType: 'simple',
  multiple: false,
  value: [],
  max: false,
  name: '',
  disabled: false,
};
