import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPersons } from '@actions';

class Institutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instances:
        props.instances.length === 0
          ? [{ index: 0, name: '' }]
          : [...props.instances],
    };
    this.onChange = this.onChange.bind(this);
    this.remove = this.remove.bind(this);
  }

  remove(i) {
    const { instances } = this.state;
    const removed = instances.splice(i, 1);
    this.setState({ instances });
    this.props.setInstitutes(instances, removed[0]);
  }
  onChange(e, i) {
    let { instances } = { ...this.state };

    instances = instances.map((inst) => {
      if (inst.index == i) {
        return { ...inst, name: e.target.value };
      }
      return inst;
    });
    this.setState({ instances });
    this.props.setInstitutes(instances);
  }
  render() {
    return (
      <div className="form-group form-institutes">
        <div>
          <label className="field-label" htmlFor="institutes">
            {window.lang[this.props.language]['institutes']}
          </label>
          <div name="institutes" className="author">
            <ol className="institute-list">
              {this.state.instances
                .sort((a, b) => a.index - b.index)
                .map((instance, i) => {
                  return (
                    <li className="institute" key={instance.index}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          window.lang[this.props.language][
                            'abstractInstitutesPlaceholder'
                          ]
                        }
                        value={instance.name}
                        onChange={(e) => this.onChange(e, instance.index)}
                        readOnly={this.props.disabled}
                        style={{ paddingLeft: 15 }}
                      />
                      <span>
                        {!this.props.disabled &&
                          this.state.instances.length > 1 && (
                            <button
                              type="button"
                              className="btn close"
                              onClick={() => this.remove(i)}>
                              <i className="icon-close" />
                            </button>
                          )}
                      </span>
                    </li>
                  );
                })}
            </ol>
            {!this.props.disabled && (
              <div className="actions">
                <button
                  className="btn author-btn btn-reversed"
                  type="button"
                  onClick={() => {
                    this.setState({
                      instances: [
                        ...this.state.instances,
                        {
                          index:
                            Math.max(
                              ...this.state.instances.map((inst) => inst.index)
                            ) + 1,
                          name: '',
                        },
                      ],
                    });
                  }}>
                  <i className="icon-add" aria-hidden="true" />
                  {window.lang[this.props.language]['addInstitutes']}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Institutes.propTypes = {
  eventId: PropTypes.number.isRequired,
  policyId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  getPersons: PropTypes.func.isRequired,
  setInstitutes: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  instances: PropTypes.array,
  language: PropTypes.string,
};

Institutes.defaultProps = {
  value: [],
  onChange: () => {},
  hasError: false,
  disabled: false,
  instances: [],
};

export default connect(
  (state) => ({
    eventId: state.api.event.data.id,
    policyId: state.api.policy.data.id,
    orgId: state.api.event.data.orgId,
    language: state.api.language.selected,
  }),
  { getPersons }
)(Institutes);
