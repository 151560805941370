import React from 'react';
import { connect } from 'react-redux';
import Modal from '@components/layout/Modal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/TurnedInNot';

import {
  changeSection,
  getSchema,
  createAbstract,
  deleteAbstractFile,
  updateAbstract,
  addNotification,
  createPerson,
  updatePerson,
  getAbstract,
  updateAbstractPublishedFiles,
  showMyAbstracts,
} from '@actions';
import PropTypes from 'prop-types';
import Authors from '../../Abstracts/partials/Authors';
import Institutes from '../../Abstracts/partials/Institutes';
import { isEmpty, isArray, isObject } from '@helpers';
import Radio from '@components/layout/Radio';
import { withRouter } from 'react-router-dom';
import KMBLoader from '@components/layout/KMBLoader';
import KMBDropzone from '@components/layout/KMBDropzone';
import Select from '@components/layout/Select2';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import { setHasUnsavedChanges } from '../../../../../../actions';
import ConfirmationModal from '../../../../../layout/ConfirmationModal';
class SingleAbstract extends React.Component {
  constructor(props) {
    super(props);
    [
      'onChange',
      'onChangeMeta',
      'getDraft',
      'deleteFile',
      'analyzeTopics',
      'analyzeTypes',
      'setInstitutes',
      'initializeFieldsMeta',
      'handleDiscard',
    ].forEach((fn) => (this[fn] = this[fn].bind(this)));
    this.state = {
      fields: {
        name: '',
        topics: [],
        types: [],
        institutes: [],
        authors: [],
        abstractText: '',
        abstractFiles: [],
        meta: this.initializeFieldsMeta(),
      },
      modalIsOpen: false,
      hasUnsavedChanges: false,
      informModal: false,
      errors: [],
      publishedAbstractThumbnail: [],
      publishedAbstractFile: [],
      ready: false,
      successModal: false,
      saveAsDraft: false,
      savePublisheFiles: false,
      filesToUpload: {
        abstractFiles: [],
        publishedAbstractThumbnail: [],
        publishedAbstractFile: [],
      },
    };

    this.abstract = {};
    this.allowEdit = true;
  }

  resetFilesToUpload() {
    this.setState({
      filesToUpload: {
        abstractFiles: [],
        publishedAbstractThumbnail: [],
        publishedAbstractFile: [],
      },
    });
  }

  setAllowEdit() {
    //todo check what happens in new abstracts
    this.allowEdit = ['awaiting_resubmission', 'draft'].includes(
      this.abstract.status
    );
  }

  navigateBack() {
    this.props.changeSection('abstractManagement', true);
  }
  initializeFieldsMeta() {
    const { meta } = this.props.schema;
    const metaFields = {};
    Object.entries(meta || {}).forEach(([key, value]) => {
      metaFields[key] = {
        key,
        name: value.name,
        type: value.type,
        value: value.type === 'yes_no' ? false : '',
      };
    });
    return meta;
  }

  setInstitutes(institutes, removed = false) {
    // toDo: this does not work on globally :this.props.setHasUnsavedChanges(true)
    // we need first check if there are differences in local state
    let { authors } = this.state.fields;
    if (removed) {
      authors = authors.map((author) => {
        author.institutes = author.institutes.filter((institute) => {
          return institute !== removed.index;
        });
        return author;
      });
    }
    const fields = { ...this.state.fields, authors, institutes };
    this.setState({ fields });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const { getSchema, orgId, eventAbstractSchemaId, eventId, abstractId } =
      this.props;
    if (eventAbstractSchemaId === null) return this.setState({ ready: true });

    //get schema for direct access from refresh
    getSchema(orgId, eventId, eventAbstractSchemaId).then((schema) => {
      const { meta } = schema.data;
      const tmeta = [];
      Object.entries(meta).forEach(([m, v]) => {
        tmeta.push({
          key: m,
          name: v.name,
          type: v.type,
          value: v.type === 'yes_no' ? false : '',
        });
      });
      //render abstract values
      if (abstractId) {
        this.getAbstract(eventId, abstractId);
      } else {
        //create new abstract
        this.setState({
          fields: Object.assign({}, this.state.fields, {
            meta: tmeta,
          }),
          ready: true,
        });
      }
    });
  }

  getAbstract(eventId, abstractId) {
    this.props.getAbstract(eventId, abstractId).then((abstract) => {
      const {
        meta,
        abstractText,
        name,
        topics,
        types,
        authors,
        institutes,
        abstractFiles,
        publishedAbstractFile,
        publishedAbstractThumbnail,
      } = abstract.data;
      this.abstract = abstract.data;
      this.setAllowEdit();
      //set state for editable fields
      this.setState(
        {
          fields: Object.assign({}, this.state.fields, {
            meta,
            abstractText,
            name,
            topics,
            types,
            abstractFiles,
            authors: authors.map((author) => {
              author.institutes = author.institutes.map((i) => {
                const instituteIndex = institutes.filter(
                  (inst) => inst.id == i
                )[0].index;
                return instituteIndex;
              });

              if (author.institutes.length === 0) {
                author.institutes =
                  authors.map((_author, i) => String(i)) || [];
              }
              return author;
            }),
            institutes:
              institutes.length > 0
                ? institutes
                : authors.map((author, i) => ({
                    index: i,
                    name: author.company,
                  })) || [],
          }),
          publishedAbstractFile,
          publishedAbstractThumbnail,
          ready: true,
        },
        () => {
          this.setInstitutes(this.state.fields.institutes);
          this.setState({ ready: true, informModal: false });
          this.resetFilesToUpload();
        }
      );
    });
  }

  onChange(e) {
    const { name, value } = e.target;
    this.props.setHasUnsavedChanges(true);
    this.setState({
      fields: Object.assign({}, this.state.fields, {
        [name]: value,
      }),
    });
  }

  charsControl(chars) {
    if (chars.length === 0) return null;
    const { schema } = this.props;
    const { minText, maxText, countTextType } = schema;
    if (countTextType === 'characters') {
      chars = chars.trim().length;
    } else {
      chars = chars.trim().split(' ').length;
    }
    if (chars < minText) {
      return (
        <p className="alert danger">
          {minText - chars} more{' '}
          {countTextType === 'characters' ? 'character' : 'word'}
          {minText - chars > 1 ? 's' : ''} required
        </p>
      );
    }
    if (chars > maxText) {
      return (
        <p className="alert alert-danger">
          Your content exceeds max allowed {countTextType} by {chars - maxText}
        </p>
      );
    }
  }

  transformTopicsToProperFormat(arr) {
    // Transform {key : Number, label : Text} into {Number : Text}
    const obj = {};
    arr.map((entry) => {
      obj[entry.key] = entry.label;
    });
    return obj;
  }

  getAcceptedFileTypes() {
    return `image/gif, image/png, image/svg, image/jpeg, image/pjpeg, image/webp, image/tiff, image/x-tiff, image/svg+xml, image/bmp, image/x-windows-bmp, image/vnd.wap.wbmp, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.oasis.opendocument.spreadsheet, video/mp4  `;
  }
  getTopicOptions() {
    const options = [];
    if (isEmpty(this.props.schema)) return options;
    return [...this.props.schema.topics].map((t) => {
      return { key: t.id.toString(), label: t.name };
    });
  }
  getJSX(key, value) {
    const { errors } = this.state;

    switch (key) {
      case 'abstractFiles': {
        return (
          <div className="form-group">
            <label className="field-label" htmlFor={key}>
              {window.lang[this.props.language]['uploadFiles']}
            </label>
            <KMBDropzone
              lang={window.lang[this.props.language]}
              active={this.allowEdit}
              max={5}
              multiple={true}
              value={this.state.fields.abstractFiles}
              accept={this.getAcceptedFileTypes()}
              onDropRejected={(message) =>
                this.props.addNotification(message, 0)
              }
              onFileDelete={(file) => {
                this.props.setHasUnsavedChanges(true);

                return this.deleteFile(file.id, 'file');
              }}
              files={this.state.filesToUpload.abstractFiles}
              onFileRemove={(index) => {
                this.props.setHasUnsavedChanges(true);

                const newFilesToUpload = { ...this.state.filesToUpload };
                newFilesToUpload.abstractFiles.splice(index, 1);
                this.setState({
                  filesToUpload: newFilesToUpload,
                });
              }}
              onDropAccepted={(files) => {
                this.props.setHasUnsavedChanges(true);

                this.setState({
                  filesToUpload: {
                    ...this.state.filesToUpload,
                    abstractFiles: files,
                  },
                });
              }}
            />
          </div>
        );
      }

      case 'name': {
        return (
          <div
            className={`form-group${
              errors.includes(key) && isEmpty(value) ? ' has-error' : ''
            }`}>
            <label className="field-label" htmlFor={key}>
              {window.lang[this.props.language]['abstractTitle']} (*)
            </label>
            <textarea
              style={{ minHeight: '40px', maxHeight: '80px', height: '40px' }}
              rows={2}
              name={key}
              id={key}
              className="form-control"
              placeholder={
                window.lang[this.props.language]['abstractTitlePlaceholder']
              }
              value={value}
              onChange={this.onChange}
              readOnly={!this.allowEdit}
            />
          </div>
        );
      }
      case 'topics': {
        let options = this.getTopicOptions();
        if (!isEmpty(this.props.schema)) {
          options = [...this.props.schema.topics].map((t) => {
            return { key: t.id.toString(), label: t.name };
          });
        }
        if (!options.length) return null;

        let value = '';

        if (
          !isArray(this.state.fields[key]) ||
          this.state.fields[key].length === 0
        ) {
          value = this.state.fields[key] || '';
        } else {
          value = [...this.state.fields[key]].map((f) =>
            isObject(f) ? f.eventAbstractSchemaTopicId.toString() : f.toString()
          );
        }
        if (options.length === 1 && (!value || value.length === 0)) {
          this.onChange({
            target: {
              name: key,
              value: [options[0].key],
            },
          });
        }
        const isMultiple = isEmpty(this.props.schema)
          ? true
          : this.props.schema.multipleTopics === 1
          ? true
          : false;

        return (
          <div
            className={`form-group${
              errors.includes(key) && isEmpty(value) ? ' has-error' : ''
            }`}>
            <label className="field-label" htmlFor="topic">
              {window.lang[this.props.language]['topics']} (*)
            </label>
            <Select
              disabled={!this.allowEdit}
              name="topics"
              placeholder={
                window.lang[this.props.language]['abstractTopicsPlaceholder']
              }
              multi={isMultiple}
              onChange={(value) => {
                this.onChange({
                  target: {
                    name: key,
                    value: [...(isMultiple ? value : [value])].map((v) =>
                      parseInt(v)
                    ),
                  },
                });
              }}
              placeholderInsideSelect={false}
              className="form-control-topics"
              value={value}
              options={this.transformTopicsToProperFormat(options)}
            />
          </div>
        );
      }

      case 'types': {
        let options = [];
        if (!isEmpty(this.props.schema)) {
          options = [...this.props.schema.types].map((t) => {
            return { key: t.id.toString(), label: t.name };
          });
        }
        let value = '';
        if (
          !isArray(this.state.fields[key]) ||
          this.state.fields[key].length === 0
        ) {
          value = this.state.fields[key] || '';
        } else {
          value = [...this.state.fields[key]].map((f) =>
            isObject(f) ? f.eventAbstractSchemaTypeId.toString() : f.toString()
          );
        }

        return (
          <React.Fragment>
            {this.props.schema.userSuggestType == 1 && (
              <div
                className={`form-group${
                  errors.includes(key) && isEmpty(value) ? ' has-error' : ''
                }`}>
                <label className="field-label" htmlFor="type">
                  {window.lang[this.props.language]['types']} (*)
                </label>

                <Select
                  multiple={false}
                  id="types"
                  placeholder={
                    window.lang[this.props.language]['abstractTypePlaceholder']
                  }
                  className="form-control-topics"
                  value={value}
                  onChange={(value) => {
                    this.onChange({
                      target: {
                        name: key,
                        value: [...[value]].map((v) => parseInt(v)),
                      },
                    });
                  }}
                  disabled={!this.allowEdit}
                  options={this.transformTopicsToProperFormat(options)}
                />
              </div>
            )}
          </React.Fragment>
        );
      }
      case 'abstractText': {
        return (
          <div
            className={`form-group${
              errors.includes(key) && isEmpty(value) ? ' has-error' : ''
            }`}>
            <label className="field-label" htmlFor={key}>
              {window.lang[this.props.language]['abstractContent']}{' '}
              {this.props.schema.minText != 0 ? '(*)' : ''}
            </label>
            <textarea
              style={{ minHeight: '40px', maxHeight: '500px' }}
              name={key}
              value={value}
              className="form-control"
              onChange={this.onChange}
              rows={13}
              placeholder={
                window.lang[this.props.language]['abstractContentPlaceholder']
              }
              readOnly={!this.allowEdit}
            />
            {this.charsControl(value)}
          </div>
        );
      }
      case 'institutes': {
        return (
          <Institutes
            hasError={errors.includes(key)}
            onChange={(value) =>
              this.onChange({ target: { name: key, value } })
            }
            instances={this.state.fields.institutes}
            setInstitutes={this.setInstitutes}
            disabled={!this.allowEdit}
          />
        );
      }

      case 'authors': {
        return (
          <Authors
            hasError={errors.includes(key)}
            onChange={(value) =>
              this.onChange({ target: { name: key, value } })
            }
            value={this.state.fields[key]}
            institutes={this.state.fields.institutes}
            disabled={!this.allowEdit}
          />
        );
      }

      case 'type': {
        return null;
      }

      case 'meta': {
        const { schema } = this.props;
        if (isEmpty(schema)) return null;
        const jsx = [];
        const entries = Object.entries(schema.meta)
          .map((v) => ({ key: v[0], value: v[1] }))
          .sort((a, b) => a.value.index - b.value.index);

        entries.forEach((v) => {
          const tkey = v.key;
          const tvalue = v.value;
          jsx.push(
            <div
              key={tkey}
              className={`abstracts-meta form-group${
                errors.includes(tkey) && isEmpty(this.state.fields.meta[tkey])
                  ? ' has-error'
                  : ''
              }`}>
              <label className="field-label" htmlFor="">
                {tvalue.name} {tvalue.required === 1 ? '(*)' : ''}
              </label>
              {this.renderMeta(tkey, tvalue)}
            </div>
          );
        });
        return <div className="extra">{jsx}</div>;
      }
    }
  }
  returnExtraUploadForPublish(primaryColor) {
    return (
      <React.Fragment>
        <br />
        <br />
        <div className="form-group row">
          <div className="col-sm-6 col-xs-12">
            <label className="field-label">
              {window.lang[this.props.language]['uploadPublishedThumbnail']}
            </label>
            <KMBDropzone
              lang={window.lang[this.props.language]}
              active={true}
              max={1}
              maxFileSize={5}
              multiple={false}
              value={this.state.publishedAbstractThumbnail}
              accept={``}
              onDropRejected={(message) =>
                this.props.addNotification(message, 0)
              }
              onFileDelete={(file) => this.deleteFile(file.id, 'pubThumb')}
              files={this.state.filesToUpload.publishedAbstractThumbnail}
              onFileRemove={(index) => {
                const newFilesToUpload = { ...this.state.filesToUpload };
                newFilesToUpload.publishedAbstractThumbnail.splice(index, 1);
                this.setState({
                  filesToUpload: newFilesToUpload,
                });
              }}
              onDropAccepted={(files) => {
                this.setState({
                  filesToUpload: {
                    ...this.state.filesToUpload,
                    publishedAbstractThumbnail: files,
                  },
                });
              }}
            />
          </div>
          <div className="col-sm-6 col-xs-12">
            <label className="field-label">
              {window.lang[this.props.language]['uploadPublishedFiles']}
            </label>
            <KMBDropzone
              lang={window.lang[this.props.language]}
              active={true}
              max={1}
              maxFileSize={100}
              multiple={false}
              value={this.state.publishedAbstractFile}
              accept={``}
              onDropRejected={(message) =>
                this.props.addNotification(message, 0)
              }
              onFileDelete={(file) => {
                this.props.setHasUnsavedChanges(true);

                return this.deleteFile(file.id, 'pubFile');
              }}
              files={this.state.filesToUpload.publishedAbstractFile}
              onFileRemove={(index) => {
                this.props.setHasUnsavedChanges(true);

                const newFilesToUpload = { ...this.state.filesToUpload };
                newFilesToUpload.publishedAbstractFile.splice(index, 1);
                this.setState({
                  filesToUpload: newFilesToUpload,
                });
              }}
              onDropAccepted={(files) => {
                this.setState({
                  filesToUpload: {
                    ...this.state.filesToUpload,
                    publishedAbstractFile: files,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="button-holder">
          <button
            disabled={
              !this.state.filesToUpload.publishedAbstractThumbnail.length &&
              !this.state.filesToUpload.publishedAbstractFile.length
            }
            className="btn btn-primary"
            type="button"
            style={{ backgroundColor: primaryColor }}
            onClick={() =>
              this.setState({ informModal: true, savePublisheFiles: true })
            }>
            {window.lang[this.props.language]['submitPublishedFiles']}
          </button>
        </div>
      </React.Fragment>
    );
  }
  onChangeMeta(key, value) {
    this.props.setHasUnsavedChanges(true);

    this.setState({
      fields: Object.assign({}, this.state.fields, {
        meta: [...this.state.fields.meta].map((m) => {
          if (m.key === key) {
            return Object.assign({}, m, { value });
          }
          return m;
        }),
      }),
    });
  }

  renderMeta(key, value) {
    switch (value.type) {
      case 'text': {
        let val = [...this.state.fields.meta].filter((v) => v.key === key);
        val = val.length === 0 ? '' : val[0].value;
        return (
          <input
            type="text"
            className="form-control"
            placeholder={window.lang[this.props.language]['typePlaceholder']}
            onChange={(e) => this.onChangeMeta(key, e.target.value)}
            value={val}
            disabled={!this.allowEdit}
          />
        );
      }
      case 'textarea': {
        let val = [...this.state.fields.meta].filter((v) => v.key === key);
        val = val.length === 0 ? '' : val[0].value;
        return (
          <textarea
            className="form-control"
            placeholder={window.lang[this.props.language]['typePlaceholder']}
            onChange={(e) => this.onChangeMeta(key, e.target.value)}
            style={{ minHeight: '40px', maxHeight: '100px', height: '40px' }}
            value={val}
            disabled={!this.allowEdit}
          />
        );
      }
      case 'dropdown': {
        const options =
          value?.value?.map?.((v) => {
            return { key: v, label: v };
          }) || [];
        let val = [...this.state.fields.meta].filter((v) => v.key === key);
        val = val.length === 0 ? '' : val[0].value;

        return (
          <Select
            options={this.transformTopicsToProperFormat(options)}
            value={val}
            placeholder={window.lang[this.props.language]['selectPlaceholder']}
            className="form-control-topics"
            onChange={(value) => this.onChangeMeta(key, value)}
            disabled={!this.allowEdit}
          />
        );
      }
      case 'yes_no': {
        let val = [...this.state.fields.meta].filter((v) => v.key === key);
        val = val.length === 0 ? '' : val[0].value;
        val = val === true || val === '1' || val === 1 ? 'yes' : 'no';
        return (
          <div className="kmb-radio">
            <Radio
              checked={val}
              id={'radio-input-' + key}
              onChange={(value) => this.onChangeMeta(key, value === 'yes')}
              disabled={!this.allowEdit}
            />
          </div>
        );
      }
    }
  }

  analyzeAndCreateAuthors(authors) {
    const { eventId, createPerson, updatePerson } = this.props;
    return new Promise((resolve) => {
      const promises = [];
      authors.forEach((author) => {
        // case abstract exists we want to update the authors
        if (author.id) {
          // case update existing author
          promises.push(
            updatePerson(
              eventId,
              {
                firstName: author.firstName,
                lastName: author.lastName,
                email: author.email.value,
                company: author.company,
              },
              author.id
            )
          );
        } else {
          // case creating author
          const data = {
            firstName: author.firstName,
            lastName: author.lastName,
            email: author.email.value,
            company: author.company,
          };
          promises.push(createPerson(eventId, data));
        }
      });
      Promise.all(promises)
        .then((res) => {
          const response = res.map((d, i) => {
            return d.hasOwnProperty('status')
              ? {
                  eventUnregisteredPersonId: d.data.id,
                  email: d.data.email,
                  type: i === 0 ? 'main' : 'other',
                  institutes: authors[i].institutes,
                }
              : d;
          });
          return resolve(response);
        })
        .catch(() => {
          this.setState({
            ready: true,
            informModal: false,
            modalIsOpen: false,
          });
        });
    });
  }

  deleteFile(fileId, param) {
    const c = confirm('Are you sure you want to delete this file?');
    const fileMap = {
      file: 'abstractFiles',
      pubThumb: 'publishedAbstractThumbnail',
      pubFile: 'publishedAbstractFile',
    };
    if (c === true) {
      const { eventId, deleteAbstractFile } = this.props;
      const data = {};
      const key = fileMap[param];
      data[key] = [fileId];

      deleteAbstractFile(eventId, this.props.abstractId, data).then((d) => {
        this.setState(
          {
            fields: Object.assign({}, this.state.fields, {
              [key]: d.data[key],
            }),
            abstractFiles: d.data.abstractFiles,
            publishedAbstractFile: d.data.publishedAbstractFile,
            publishedAbstractThumbnail: d.data.publishedAbstractThumbnail,
          },
          () => this.props.addNotification(`File deleted successfully!`)
        );
      });
    }
  }

  analyzeTopics(topics) {
    if (isObject(topics[0])) {
      topics = topics.map((t) => t.eventAbstractSchemaTopicId);
    }

    return topics || [];
  }

  analyzeTypes(types) {
    if (!this.props.schema.userSuggestType) return undefined;
    if (isObject(types[0])) {
      types = types.map((t) => t.eventAbstractSchemaTypeId);
    }

    return types || [];
  }
  normalizeRequestData() {
    const { saveAsDraft } = this.state;
    const { name, topics, types, institutes, authors, abstractText, meta } =
      this.state.fields;

    const { abstractFiles, publishedAbstractFile, publishedAbstractThumbnail } =
      this.state.filesToUpload;

    const parsedMeta = {};
    Object.entries(meta).map(([key, value]) => {
      if (isObject(value)) {
        parsedMeta[value.key] = value.value;
      } else {
        parsedMeta[key] = value;
      }
    });

    if (this.state.savePublisheFiles) {
      return { publishedAbstractFile, publishedAbstractThumbnail };
    }

    const data = {
      status: saveAsDraft ? 'draft' : 'submitted',
      name,
      authors,
      abstractText,
      meta: parsedMeta,
      topics: this.analyzeTopics(topics),
      institutes: institutes
        .map((institute) => ({
          id: institute.id,
          index: institute.index,
          name: institute.name,
        }))
        .filter((institute) => institute.name !== ''),
    };
    if (this.props.schema.userSuggestType === 1) {
      data['types'] = this.analyzeTypes(types);
    }
    if (abstractFiles.length) {
      data.abstractFiles = abstractFiles;
    }
    if (abstractFiles.length) {
      data.publishedAbstractFile = publishedAbstractFile;
    }
    if (abstractFiles.length) {
      data.publishedAbstractThumbnail = publishedAbstractThumbnail;
    }
    const errors = [];

    if (isEmpty(name)) {
      errors.push('name');
    }

    if (
      authors.length === 0 ||
      isEmpty(authors[0].firstName) ||
      isEmpty(authors[0].lastName)
    ) {
      errors.push('authors');
    }

    if (isEmpty(abstractText) && this.props.schema.minText != 0) {
      errors.push('abstractText');
    }
    if (isEmpty(topics) && this.getTopicOptions().length > 0) {
      errors.push('topics');
    }

    Object.entries(data.meta).forEach(([k, v]) => {
      if (
        v.hasOwnProperty('required') &&
        v.required === 1 &&
        isEmpty(v.value) &&
        v.type !== 'yes_no'
      ) {
        errors.push(k);
      }
    });

    if (this.props.schema.userSuggestType && isEmpty(types)) {
      errors.push('types');
    }

    if (errors.length > 0) {
      return this.setState({ errors, informModal: false }, () =>
        this.props.addNotification(
          'Please fill in all the required fields correctly',
          0
        )
      );
    }
    return data;
  }
  performRequest(data) {
    data.authors = data.authors.map((author) => {
      if (!author.id) delete author.id;
      if (author.email.hasOwnProperty('value'))
        author.email = author?.email?.value || '';
      return author;
    });
    let promise = null;
    if (this.props.abstractId) {
      promise = this.props.updateAbstract(
        this.props.eventId,
        this.props.abstractId,
        data
      );
    } else {
      promise = this.props.createAbstract(this.props.eventId, data);
    }

    promise
      .then(() => {
        this.props.addNotification(
          `Abstract ${
            this.state.saveAsDraft ? 'saved as a draft' : ' submitted'
          } successfully!`
        );
        this.getAbstract(this.props.eventId, this.props.abstractId);
      })
      .catch(() => {
        this.props.addNotification(
          `Abstract ${
            this.state.saveAsDraft
              ? 'was not saved as a draft'
              : 'was not submitted'
          }, there has been an error!`,
          0
        );
        this.setState({
          informModal: false,
          ready: true,
          files: [],
          publishedAbstractFile: [],
          publishedAbstractThumbnail: [],
        });
      });
  }
  savePublishedFiles(data) {
    this.props
      .updateAbstractPublishedFiles(
        this.props.eventId,
        this.props.abstractId,
        data
      )
      .then((d) => {
        this.props.addNotification(
          `Abstract Published files uploaded successfully!`
        );
        this.resetFilesToUpload();
        this.setState({
          informModal: false,
          ready: true,
          publishedAbstractThumbnail: d.data.publishedAbstractThumbnail,
          publishedAbstractFile: d.data.publishedAbstractFile,
        });
      })
      .catch(() => {
        this.props.addNotification(
          `Abstract published files were not uploaded!`,
          0
        );
        this.setState({
          informModal: false,
          ready: true,
          files: [],
          publishedAbstractFile: [],
          publishedAbstractThumbnail: [],
        });
      });
  }
  submit() {
    const data = this.normalizeRequestData();
    if (!data) return;
    this.setState({ ready: false, informModal: true }, () => {
      if (this.state.savePublisheFiles) {
        this.savePublishedFiles(data);
      } else {
        this.performRequest(data);
      }
    });
  }

  getDraft() {
    if (this.abstract.status === 'draft') {
      return <small> ({window.lang[this.props.language]['draft']})</small>;
    }
    return null;
  }
  handleDiscard() {
    this.setState({ hasUnsavedChanges: false });
    this.props.setHasUnsavedChanges(false);
  }
  render() {
    const { comments = [], status = '' } = this.abstract;
    const showPublishedRelatedFields =
      Boolean(!this.allowEdit) &&
      Boolean(this.abstract.published) &&
      Boolean(this.props.schema.enableUploadFilesForPublished);
    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;

    return (
      <div className="single-abstract">
        <div
          className="navigate-back-abstracts"
          onClick={() => this.navigateBack()}>
          <ArrowBackIcon />
          {lang[this.props.language]['backToMyAbstract']}
        </div>
        {this.props.eventAbstractSchemaId === null && (
          <div className="empty-results">
            <span className="icon-warning-2" />

            <p>{window.lang[this.props.language]['noAbstractSchema']}</p>
          </div>
        )}
        <h3 className="abstract-header">
          {window.lang[this.props.language]['abstractForm']}
        </h3>
        <p className="abstract-subheader">
          {window.lang[this.props.language]['abstractInfo']}
        </p>
        {this.props.eventAbstractSchemaId !== null && (
          <div>
            <div className={`status status-${status}`}>
              {window.lang[this.props.language]['status']}:{' '}
              <span>
                {(
                  window.lang[this.props.language][status] ||
                  window.lang[this.props.language]['newAbstractStatus']
                ).replace('_', ' ')}
              </span>
            </div>
            {!this.allowEdit && (
              <p className="alert alert-info">
                {window.lang[this.props.language]['abstractSubmitted']}
              </p>
            )}
            {comments.length > 0 && (
              <div className="comments-box alert alert-primary">
                <h5>{window.lang[this.props.language]['judgeComments']}</h5>
                {[...comments].reverse().map((c) => {
                  return (
                    <div key={c.id} className={`comment type-${c.commentType}`}>
                      <div className="meta">
                        <span className="date">{c.updatedAt.tz}</span>
                        {c.commentType.includes('revision') && (
                          <span className="revision">
                            {c.commentType.replace('_', ' ')}
                          </span>
                        )}
                      </div>

                      {c.commentText}
                    </div>
                  );
                })}
              </div>
            )}

            {!this.state.ready ? (
              <KMBLoader rows={40} />
            ) : (
              <form
                action=""
                autoComplete="off"
                className={!this.allowEdit ? 'disabled' : ''}>
                {this.props.schema.rules && (
                  <>
                    <Accordion
                      expanded={true}
                      onChange={() => {
                        this.setState({
                          accordionExpanded: !this.state.accordionExpanded,
                        });
                      }}
                      style={{
                        transition: 'max-height 0.3s ease',
                        overflow: 'hidden',
                      }}>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            style={{ color: 'var(--warning-800)' }}
                            className={
                              !this.state.accordionExpanded ? 'expanded' : ''
                            }
                          />
                        }
                        aria-controls="panel3a-content"
                        id="panel3a-header">
                        <Box style={{ display: 'flex', gap: '5px' }}>
                          <InfoIcon style={{ color: 'var(--warning-800)' }} />
                          <div>
                            <Typography
                              style={{
                                paddingTop: '1px',
                                color: 'var(--warning-800)',
                              }}>
                              {
                                window.lang[this.props.language][
                                  'submissionRules'
                                ]
                              }
                            </Typography>
                          </div>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          opacity: this.state.accordionExpanded ? 1 : 0.2, // Adjust for fade effect
                          transition: 'opacity,max-height 0.3s ease',
                          maxHeight: this.state.accordionExpanded
                            ? '2000px'
                            : '150px',
                          overflow: this.state.accordionExpanded
                            ? 'auto'
                            : 'hidden',
                          paddingBottom: '70px',
                        }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.props.schema.rules,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}

                {Object.entries(this.state.fields).map(([k, v]) => {
                  return <div key={k}>{this.getJSX(k, v)}</div>;
                })}
                {showPublishedRelatedFields &&
                  this.returnExtraUploadForPublish(primaryColor)}
                {this.allowEdit && (
                  <div className="button-holder">
                    {this.abstract.status !== 'awaiting_resubmission' ? (
                      <button
                        className="btn btn-orange"
                        type="button"
                        style={{
                          color: primaryColor,
                          borderColor: primaryColor,
                        }}
                        onClick={() =>
                          this.setState({
                            informModal: true,
                            saveAsDraft: true,
                          })
                        }>
                        {window.lang[this.props.language]['saveAsDraft']}
                      </button>
                    ) : null}
                    <button
                      className="btn btn-primary"
                      type="button"
                      style={{ backgroundColor: primaryColor }}
                      onClick={() =>
                        this.setState({ modalIsOpen: true, saveAsDraft: false })
                      }>
                      {window.lang[this.props.language]['submitAbstract']}
                    </button>
                  </div>
                )}
              </form>
            )}
          </div>
        )}
        <Modal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={() => this.setState({ modalIsOpen: false })}
          inner={[
            <div key="main-1">
              <p key="main-2">
                {window.lang[this.props.language]['submitAbstractDescription']}{' '}
                {window.lang[this.props.language]['submitDescription']}
              </p>
              <div style={{ textAlign: 'right' }} key="main-3">
                <button
                  key="main-4"
                  style={{ margin: '5px' }}
                  className="btn btn-cancel"
                  onClick={() => this.setState({ modalIsOpen: false })}>
                  Cancel
                </button>
                <button
                  key="main-5"
                  style={{ margin: '5px' }}
                  className="btn btn-primary"
                  onClick={() =>
                    this.setState({ modalIsOpen: false, informModal: true })
                  }>
                  OK
                </button>
              </div>
            </div>,
          ]}
        />
        <Modal
          modalIsOpen={this.state.informModal}
          closeModal={() => this.setState({ informModal: false })}
          afterOpenModal={() => this.submit()}
          noClose={true}
          inner={[
            <div key="main-1">
              <div
                key="main-2"
                style={{ textAlign: 'center', fontSize: '38px' }}>
                <i className="icon-bubble" />
              </div>
              <p key="main-3">
                {window.lang[this.props.language]['waitUntilFinish']}
              </p>
            </div>,
          ]}
        />
        <ConfirmationModal
          onDiscard={this.handleDiscard}
          hasPreventChangeSection={this.state.hasUnsavedChanges}
        />
      </div>
    );
  }
}

SingleAbstract.defaultProps = {
  name: '',
  abstractText: '',
  topics: [],
  status: '',
  type: '',
  authors: [],
  abstractFiles: [],
  meta: {},
};

SingleAbstract.propTypes = {
  language: PropTypes.string,
  name: PropTypes.string,
  abstractText: PropTypes.string,
  topics: PropTypes.array,
  type: PropTypes.string,
  status: PropTypes.string,
  authors: PropTypes.array,
  abstractFiles: PropTypes.array,
  meta: PropTypes.object,
  orgId: PropTypes.number,
  changeSection: PropTypes.func.isRequired,
  getSchema: PropTypes.func.isRequired,
  schemaId: PropTypes.number,
  eventAbstractSchemaId: PropTypes.number,
  schema: PropTypes.object,
  eventId: PropTypes.number.isRequired,
  createAbstract: PropTypes.func.isRequired,
  deleteAbstractFile: PropTypes.func.isRequired,
  updateAbstractPublishedFiles: PropTypes.func.isRequired,
  updateAbstract: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  createPerson: PropTypes.func.isRequired,
  updatePerson: PropTypes.func.isRequired,
  getAbstract: PropTypes.func.isRequired,
  setHasUnsavedChanges: PropTypes.func.isRequired,
  abstractId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  fetching: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  showMyAbstracts: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    (state) => ({
      eventId: state.api.event.data.id,
      event: state.api.event.data,
      policyId: state.api.policy.data.id,
      orgId: state.api.event.data.orgId,
      eventAbstractSchemaId: state.api.event.data.eventAbstractSchemaId,
      schema: state.api.schema.data,
      abstractId: state.api.abstracts.showingAbstract.active
        ? state.api.abstracts.showingAbstract.abstractId
        : false,
      abstract: state.api.abstracts.showingAbstract.data,
      fetching: !state.api.abstracts.showingAbstract.ready,
      language: state.api.language.selected,
    }),
    {
      changeSection,
      setHasUnsavedChanges,
      getSchema,
      createAbstract,
      deleteAbstractFile,
      updateAbstractPublishedFiles,
      updateAbstract,
      addNotification,
      createPerson,
      updatePerson,
      getAbstract,
      showMyAbstracts,
    }
  )(SingleAbstract)
);
