import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  changeSection,
  getAbstracts,
  showAbstract,
  getSchema,
  createNewAbstract,
  deleteAbstract,
  showMyAbstracts,
  showPublishedAbstracts,
} from '@actions';
import MyAbstracts from './partials/MyAbstracts';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AbstractCard from './partials/AbstractCard';
import KMBLoader from '@components/layout/KMBLoader';
import Toolbar from '@components/layout/Toolbar';

const Abstracts = (props) => {
  window.scrollTo(0, 0);
  const delayDebounceFn = React.useRef(null);
  const nullEntry = lang[props.language]['selectTopic'];

  const [typeOptions, setTypeOptions] = React.useState({});
  const [topicOptions, setTopicOptions] = React.useState({});
  const [firstSearch, setFirstSearch] = React.useState(true);
  const [viewIndex, setViewIndex] = React.useState(0);
  const [searchPrompt, setSearchPrompt] = React.useState('');
  const [selectedTopics, setSelectedTopics] = React.useState([nullEntry]);

  //!!! TEST AND/OR TEMPORARY PLACEHOLDER VARIABLES !!! //
  const withThumbnail = true;
  // const withThumbnail = false;
  //!!! TEST AND/OR TEMPORARY PLACEHOLDER VARIABLES !!! //

  React.useEffect(() => {
    getAbstracts();
    if (props.eventAbstractSchemaId) {
      props.getSchema(props.orgId, props.eventId, props.eventAbstractSchemaId);
    }
  }, [props.abstractTabIndex]);

  React.useEffect(() => {
    clearTimeout(delayDebounceFn.current);

    delayDebounceFn.current = setTimeout(() => {
      if (firstSearch) setFirstSearch(false);
      else getAbstracts();
    }, 500);
    return () => clearTimeout(delayDebounceFn.current);
  }, [searchPrompt]);

  const handleTabChange = (event, newTabValue) => {
    switch (newTabValue) {
      case 0:
        props.showPublishedAbstracts();
        getAbstracts();
        if (props.eventAbstractSchemaId) {
          props.getSchema(
            props.orgId,
            props.eventId,
            props.eventAbstractSchemaId
          );
        }
        break;
      case 1:
        props.showMyAbstracts();
        break;
    }
  };
  React.useEffect(() => {
    const typeOptions = {};
    const topicOptions = {};

    props.schema?.types?.map((type) => {
      typeOptions[type.id] = type.name;
    });
    props.schema?.topics?.map((topics) => {
      topicOptions[topics.id] = topics.name;
    });

    setTypeOptions(typeOptions);
    setTopicOptions(topicOptions);
  }, [props.schema]);

  const handleTypeChange = (event) => {
    setViewIndex(event.target.value);
    getAbstracts(event.target.value);
  };

  const handleTopicChange = (event) => {
    setSelectedTopics(event.target.value);
    getAbstracts('', event.target.value.join(','));
  };

  const getTopicIds = (topicTitles) =>
    topicTitles.map((topicTitle) => {
      const topicIndex = props.schema.topics.findIndex(
        (topic) => topic.name === topicTitle
      );
      if (topicIndex === -1) {
        return;
      }
      return props.schema.topics[topicIndex].id;
    });

  const getAbstracts = (type = '', topicsList = selectedTopics) => {
    if (typeof topicsList !== 'string') {
      topicsList = topicsList.filter((key) => {
        return key !== nullEntry;
      });
      topicsList = topicsList.length > 0 ? topicsList : [''];
    }
    props.getAbstracts(props.eventId, {
      rpp: props.meta.rpp ?? 10,
      p: 1,
      order: 'ASC',
      orderBy: 'publishedId',
      search: searchPrompt,
      topicIds: topicsList,
      typeIds: type === 0 ? '' : type,
      published: true,
    });
  };

  const handleSearch = (event) => setSearchPrompt(event.target.value);

  const hasSubscription =
    Object.keys(props.user?.subscription || {}).length > 0;
  const registrationCompleted = props.mandatoryPayment
    ? props.user.subscriptionDueAmount <= 0
    : true;

  const renderTabContent = (index) => {
    if (props.event.clientPanelSettings.abstracts.showPublishedAbstracts) {
      if (index === 0) {
        if (hasSubscription && !registrationCompleted) {
          return (
            <div className="abstract-paywall">
              {lang[props.language]['abstractsPaywall']}
            </div>
          );
        }
        return renderAbstractList();
      } else if (index === 1) {
        return renderMyAbstracts();
      }
    } else {
      if (index === 0) {
        return renderMyAbstracts();
      }
    }
  };

  const renderAbstractList = () => {
    return (
      <React.Fragment>
        <Toolbar
          singleSelects={[
            {
              data: typeOptions,
              callback: handleTypeChange,
              nullEntry: lang[props.language]['selectType'],
              currentVal: viewIndex,
            },
          ]}
          multiSelects={[
            {
              data: topicOptions,
              callback: handleTopicChange,
              nullEntry: lang[props.language]['selectTopic'],
              currentVal: selectedTopics,
            },
          ]}
          handleSearch={handleSearch}
          getItems={(meta) =>
            props.getAbstracts(props.eventId, {
              ...meta,
              search: searchPrompt,
              topicIds: getTopicIds(
                selectedTopics.filter((topic) => topic !== nullEntry)
              ).join(','),
              typeIds: (viewIndex ?? '') === 0 ? '' : viewIndex,
              published: true,
              order: 'ASC',
              orderBy: 'publishedId',
            })
          }
          items={props.abstracts}
          meta={props.meta}
          searchPrompt={searchPrompt}
          fetching={props.fetching}
        />
        <div className="myaccount-divider" />
        {props.fetching ? (
          <KMBLoader height={384} rows={10}></KMBLoader>
        ) : (
          <React.Fragment>
            {props.abstracts?.length > 0 && (
              <div className={`abstract-card-wrapper`}>
                {props.abstracts.map((abstract, i) => (
                  <AbstractCard
                    key={`abstract-${i}`}
                    abstract={abstract}
                    showThumbnail={withThumbnail}></AbstractCard>
                ))}
              </div>
            )}
            {!props.abstracts?.length && (
              <div className="no-abstracts-found">
                {lang[props.language]['noAbstractsFound']}
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const renderMyAbstracts = () => {
    return (
      props.policy.data.type !== 'public' && (
        <React.Fragment>
          <MyAbstracts />
        </React.Fragment>
      )
    );
  };

  const showBothPublishedAndMyAbstracts =
    props.policy.data.type !== 'public' &&
    props.event.clientPanelSettings.abstracts.showPublishedAbstracts &&
    props.user.id;
  const tabindex = showBothPublishedAndMyAbstracts ? props.abstractTabIndex : 0;
  return (
    <div className="abstracts-wrapper">
      <div className="abstracts-header">
        <Tabs
          className="custom-tabs"
          value={tabindex}
          onChange={handleTabChange}
          aria-label="simple tabs example">
          {props.event.clientPanelSettings.abstracts.showPublishedAbstracts && (
            <Tab label={lang[props.language]['publishedAbstracts']} />
          )}
          {props.policyType !== 'public' && props.user.id && (
            <Tab label={lang[props.language]['myAbstracts']} />
          )}
        </Tabs>
      </div>
      {renderTabContent(tabindex)}
    </div>
  );
};

Abstracts.propTypes = {
  changeSection: PropTypes.func.isRequired,
  getAbstracts: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  abstracts: PropTypes.array,
  meta: PropTypes.object,
  showAbstract: PropTypes.func.isRequired,
  showPublishedAbstracts: PropTypes.func.isRequired,
  showMyAbstracts: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  mandatoryPayment: PropTypes.number,
  language: PropTypes.string,
  getSchema: PropTypes.func,
  orgId: PropTypes.number,
  eventAbstractSchemaId: PropTypes.number,
  schema: PropTypes.object,
  policyType: PropTypes.string,
  event: PropTypes.object,
  policy: PropTypes.object,
  abstractTabIndex: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.api.event.data.id,
    policy: state.api.policy,
    policyType: state.api.policy.data.type,
    abstracts: state.api.abstracts.data,
    abstractTabIndex: state.api.abstracts.abstractTabIndex,
    meta: state.api.abstracts.meta,
    user: state.api.user.data,
    fetching: state.api.abstracts.fetching,
    language: state.api.language.selected,
    mandatoryPayment: state.api.policy.data.mandatoryPayment,
    eventAbstractSchemaId: state.api.event.data.eventAbstractSchemaId,
    ready: state.api.abstracts.ready,
    orgId: state.api.event.data.orgId,
    schema: state.api.schema.data,
    event: state.api.event.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSection: (section) => dispatch(changeSection(section)),
  createNewAbstract: () => dispatch(createNewAbstract()),
  getAbstracts: (eventID, meta) => dispatch(getAbstracts(eventID, meta)),
  showAbstract: (abstractID) => dispatch(showAbstract(abstractID)),
  showMyAbstracts: () => dispatch(showMyAbstracts()),
  showPublishedAbstracts: () => dispatch(showPublishedAbstracts()),
  deleteAbstract: (eventID, abstractID) =>
    dispatch(deleteAbstract(eventID, abstractID)),
  getSchema: (orgID, eventID, eventAbstractSchemaID) =>
    dispatch(getSchema(orgID, eventID, eventAbstractSchemaID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Abstracts);
