import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AccountIcon } from '@dynamic_assets/accounticon.svg';
import { getBadge } from '@actions';
import Button from '@material-ui/core/Button';
import PasswordResetModal from './partials/PasswordResetModal';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import EditInfoModal from './partials/EditInfoModal';
import Barcode from 'react-barcode';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkIfConditionIsFulfilled } from '../../../../../helpers';

const AccountInfo = (props) => {
  const [passwordResetIsOpen, setPasswordResetIsOpen] = React.useState(false);
  const [editInfoIsOpen, setEditInfoIsOpen] = React.useState(false);

  const printBadge = () => {
    if (props.badgeLoading) {
      return;
    }
    props.getBadge(props.event.id);
  };

  const closePasswordReset = () => {
    setPasswordResetIsOpen(false);
  };

  const openPasswordReset = () => {
    setPasswordResetIsOpen(true);
  };

  const openEditInfo = () => {
    setEditInfoIsOpen(true);
  };

  const closeEditInfo = () => {
    setEditInfoIsOpen(false);
  };

  const parseFieldValue = (field) => {
    const value = props.user.info[field.key];
    if (field.key === 'password') {
      return (
        <div
          className="password-reset-prompt"
          onClick={() => openPasswordReset()}>
          {lang[props.language]['passwordRequest']}
        </div>
      );
    }
    if (value && value.length !== 0) {
      return field.type === 'yes_no'
        ? `${value === '1' ? 'Yes' : value === '0' ? 'No' : null}`
        : `${value}`;
    } else {
      return 'N/A';
    }
  };
  const userOwes =
    props.user.subscriptionDueAmount > 0 && props.user.info?.sponsored != 1;
  return (
    <div className="my-account-container">
      {passwordResetIsOpen && (
        <PasswordResetModal
          user={props.user}
          requestResetPassword={props.requestResetPassword}
          openModal={openPasswordReset}
          closeModal={closePasswordReset}
          modalIsOpen={passwordResetIsOpen}
        />
      )}
      {editInfoIsOpen && (
        <EditInfoModal
          user={props.user}
          openModal={openEditInfo}
          closeModal={closeEditInfo}
          modalIsOpen={editInfoIsOpen}
        />
      )}

      <div className="my-account-header">
        {lang[props.language]['myAccount']}
      </div>
      <div className="info-wrapper">
        <div className="profile-info">
          <div className="badge-wrapper">
            {/* todo: UPDATE SECTION WHEN PROFILE PICS ARE AVAILABLE */}
            <div className="user-account-no-avatar-wrapper">
              <div className="user-account-no-avatar">
                <AccountIcon color={window.colorPalette.primary[600]} />
              </div>
            </div>
            <div className="user-credentials">
              <div className="user-id">{`ID ${props.user.id}`}</div>
              <div className="user-name">
                {`${props.user.info.firstName || ''} ${
                  props.user.info.lastName || ''
                }`}
                <Tooltip
                  title={
                    <div
                      style={{ fontSize: '14px', lineHeight: '18px' }}
                      className="user-name-warning">
                      {lang[props.language]['userNameWarning']}
                    </div>
                  }>
                  <InfoOutlinedIcon />
                </Tooltip>
              </div>
              {props.user.info.email?.length > 0 && (
                <div className="user-email">{`${props.user.info.email}`}</div>
              )}
              {props.policy.type === 'private' && (
                <div className="user-barcode">
                  <Barcode
                    displayValue={false}
                    width="2"
                    height="45"
                    value={props.user.id}
                  />
                </div>
              )}
              {props.policy.type === 'private' && (
                <div
                  className={`print-badge-wrapper${
                    userOwes ? ' disabled' : ''
                  }`}>
                  <Button
                    disabled={userOwes}
                    onClick={printBadge}
                    className="print-badge-button"
                    variant="contained">
                    {props.badgeLoading ? (
                      <CircularProgress
                        color="var(--primary-600)"
                        size={24}
                        className="submit-loader"
                      />
                    ) : (
                      lang[props.language]['printBadge']
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <AccountStats {...props} /> */}
      </div>
      <div className="myaccount-divider" />
      <div className="info-fields-display">
        {props.policy?.fields?.map((field) => {
          if (field.type === 'section') return;
          if (checkIfConditionIsFulfilled(field, props.user.info) === false)
            return null;
          if (
            (props.policy.type === 'public' && field.key === 'password') ||
            (field.key === 'username' && props.policy.type === 'public')
          ) {
            return null;
          }
          return (
            <div
              className={`user-field user-field-${field.key}`}
              key={`user-field-${field.key}`}>
              <div className="field-title">{field.name}</div>
              <div className="field-value">{parseFieldValue(field)}</div>
            </div>
          );
        })}
      </div>
      <div className="process-info-wrapper">
        {props.policy.type === 'private' && (
          <Button
            onClick={printBadge}
            className="print-badge-button print-badge-button-mobile"
            variant="contained">
            {props.badgeLoading ? (
              <CircularProgress
                color="var(--primary-600)"
                size={24}
                className="submit-loader"
              />
            ) : (
              lang[props.language]['printBadge']
            )}
          </Button>
        )}

        <Button
          onClick={openEditInfo}
          className="process-info-button"
          variant="contained">
          {lang[props.language]['edit']}
        </Button>
      </div>
    </div>
  );
};

AccountInfo.propTypes = {
  policy: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  currency: PropTypes.string,
  updateUser: PropTypes.func,
  event: PropTypes.object,
  requestResetPassword: PropTypes.func,
  primaryColor: PropTypes.string,
  getBadge: PropTypes.func,
  getCert: PropTypes.func,
  badgeLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    language: state.api.language.selected,
    badgeLoading: state.api.user.badgeLoading,
    currency: state.api.event.data.currencySymbol,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getBadge: (eventId) => dispatch(getBadge(eventId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
