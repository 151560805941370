import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

const AddButton = ({
  isConfirmed,
  maxQuantityReached,
  isMyProducts,
  language,
  showQuantityControls,
  numberOfProductsInCart,
  onChangeQuantity,
  setShowQuantityControls,
  remainingQuantity,
  onAction,
}) => {
  const isAllProductsText = window.lang[language]['addToCart'];
  const isMyProductsText = isConfirmed
    ? window.lang[language]['confirmedPayment']
    : window.lang[language]['payoff'];

  const toggleQuantityControls = () => {
    setShowQuantityControls(!showQuantityControls);
    if (showQuantityControls) return false;
    if (numberOfProductsInCart >= remainingQuantity) return false;
    if (maxQuantityReached) return false;

    const newQuantity = numberOfProductsInCart + 1;
    onChangeQuantity?.(newQuantity);
  };
  const isDisabled =
    isConfirmed || maxQuantityReached || remainingQuantity <= 0;
  const buttonProps = {
    className: `add-button ${isDisabled ? 'disabled' : ''}`,
    onClick: isMyProducts
      ? onAction
      : isDisabled
      ? null
      : toggleQuantityControls,
  };

  // render a disabled button if the user has reached the maximum quantity
  if (isDisabled) {
    return (
      <Tooltip
        disableFocusListener
        leaveDelay={0}
        title={window.lang[language]['maxQuantityReached']}>
        <button {...buttonProps}>
          {isMyProducts ? isMyProductsText : isAllProductsText}
        </button>
      </Tooltip>
    );
  }

  return (
    <button {...buttonProps}>
      {isMyProducts ? isMyProductsText : isAllProductsText}
    </button>
  );
};

AddButton.propTypes = {
  isConfirmed: PropTypes.bool,
  maxQuantityReached: PropTypes.bool,
  isMyProducts: PropTypes.bool,
  toggleQuantityControls: PropTypes.func,
  isAllProductsText: PropTypes.string,
  isMyProductsText: PropTypes.string,
  language: PropTypes.string,
  showQuantityControls: PropTypes.bool,
  numberOfProductsInCart: PropTypes.number,
  onChangeQuantity: PropTypes.func,
  setShowQuantityControls: PropTypes.func,
  remainingQuantity: PropTypes.number,
  onAction: PropTypes.func,
};

export default AddButton;
